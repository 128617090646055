import { useCallback, useEffect, useRef, useState } from "react";
import TestService from "../services/httpServices/testService";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ToastService from "../services/toastService";
import dateService from "../services/date.service";
import Skeleton from "react-loading-skeleton";
import IconEye from "../../assets/icons/test-svgrepo-com.svg";
import IconTrash from "../../assets/icons/IconTrash.svg";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import MainButton from "../components/buttons/MainButton";
import { ROUND_BUTTON } from "../config/constants";



export default function RecoverTestsUnfinished(): JSX.Element {
  const navigate = useNavigate();
  const { getTestNoFinalized, recoverSpeciTestGVA, recoverSpeciTestLaw, deleteTestRecover, recoverCustomTestUnfinished, recoverTestWithDoubtful } = TestService();
  const [openModal, setOpenModal] = useState({ visible: false, testId: '' });
  const { showErrorToast } = ToastService();
  const [testUnfinished, setTestUnfinished] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [page, setPage] = useState<number>(1);
  const [deletTestLoading, setDeleteTestLoading] = useState<boolean>(false);
  const [hasQuestionsDoubtful, setHasQuestionsDoubtful] = useState<boolean>(false);



  const getTestQuestionsNoFinalized = async (page: number, pageSize: number) => {
    try {
      setIsLoading(true);
      const res = await getTestNoFinalized(page, pageSize);
      setTestUnfinished(prevTests => [...prevTests, ...res.data]);
      setHasMore(res.hasMore);
      setHasQuestionsDoubtful(res.hasQuestionsDoubtful);
    } catch (error) {
      showErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTestQuestionsDoubtful = async () => {
    try {
      const res = await recoverTestWithDoubtful();
      navigate('/testUnfinished/doTestUnfinished', {
        state: {
          type: 'testWithDoubtful',
          test: {
            ...res,

          }
        }
      });

    } catch (error) {
      showErrorToast(error);
    }

  }

  const lastItemRef = useCallback((node: HTMLDivElement | null) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prev => prev + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, testUnfinished]);

  const recoverTestGVA = async (testName: any, idTestRecovered: any, timeLeft: number) => {
    try {
      const res = await recoverSpeciTestGVA(testName, idTestRecovered);
      navigate('/testUnfinished/doTestUnfinished', {
        state: {
          type: 'unfinishedTest',
          test: {
            _id: idTestRecovered,
            timeLeft,
            idTestRecovered,
            ...res,
          }
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const recoverTesLaw = async (subjectBlock: string, standard: string, idTestRecovered: string, timeLeft: number) => {
    try {
      const res = await recoverSpeciTestLaw(subjectBlock, idTestRecovered, standard);
      navigate('/testUnfinished/doTestUnfinished', {
        state: {
          type: 'unfinishedTest',
          test: {
            _id: idTestRecovered,
            timeLeft,
            idTestRecovered,
            ...res,
          }
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };
  const recoverCustomTest = async (idTestRecovered: any, timeLeft: number) => {
    try {
      const res = await recoverCustomTestUnfinished(idTestRecovered);
      navigate('/testUnfinished/doTestUnfinished', {
        state: {
          type: 'customTest',
          test: {
            _id: idTestRecovered,
            timeLeft,
            idTestRecovered,
            ...res,
          }
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const deleteTestUnfinished = async (testId: string) => {
    setDeleteTestLoading(true);
    try {
      await deleteTestRecover(testId);
      setTestUnfinished(prev => prev.filter(test => test._id !== testId));
    } catch (error) {
      showErrorToast(error);
    } finally {
      setDeleteTestLoading(false);
    }
  };

  useEffect(() => {
    getTestQuestionsNoFinalized(page, 5);
  }, [page]);

  useEffect(() => {
    if (testUnfinished.length === 0) {
      setHasQuestionsDoubtful(false);
    }
  }, [testUnfinished]);


  return (
    <>
      <div className='flex-1 pt-10 sm:pb-20 border-t-[1px] border-gray-200 '>
        <Outlet />
        <div className="flex flex-col items-center px-8">
          <div className=" flex justify-between mt-4 mb-8 w-[800px] max-sm:w-[500px] max-sm:flex-col max-sm:px-10">
            <h2 className="text-3xl font-bold text-text-100 text-center">
              Mis test guardados
            </h2>
            <MainButton
              className="w-full sm:w-auto max-sm:mt-4 max-sm:w-[70%] max-sm:m-auto"
              type={ROUND_BUTTON}
              disabled={isLoading || !hasQuestionsDoubtful}
              onClick={() => getTestQuestionsDoubtful()}

            >
              Hacer test de preguntas dudosas
            </MainButton>
          </div>

          {/* <p className="mt-4 mb-8 text-text-300 text-center">
            Mis test guardados
          </p> */}

          {/* {isLoading && (
          <div className="flex flex-col w-full max-w-[800px] relative justify-center mb-8">
            <Skeleton className="min-w-[800px]" height={120} />
            <Skeleton className="min-w-[800px]" height={120} />
            <Skeleton className="min-w-[800px]" height={120} />
            <Skeleton className="min-w-[800px]" height={120} />
          </div>
        )} */}

          {!isLoading && testUnfinished.length === 0 && (
            <div className="flex w-full max-w-[800px] h-[400px] bg-primary-300 rounded-2xl items-center justify-center mb-8">
              <p className="text-text-300 font-extrabold">
                No hay test guardados
              </p>
            </div>
          )}

          {testUnfinished.map((test, i) => {
            return (
              <div
                ref={i === testUnfinished.length - 1 ? lastItemRef : null}
                key={test._id}
                className="flex w-full max-w-[800px] relative rounded-lg ring-1 ring-gray-300 mb-10 box-border overflow-hidden"
              >
                <div className="flex flex-col gap-1 w-[20%]">
                  <div className="py-3 px-2 bg-[#e6f4ff] gap-3">
                    <p className="font-bold max-sm:text-[10px] ">Fechas:</p>
                    <p className="mt-2 max-sm:text-[10px]">{dateService.format(test.created_at, 'MM/D/YYYY')}</p>
                  </div>
                  <div className="py-3 px-2 bg-[#e6f4ff] gap-3">
                    <p className="font-bold max-sm:text-[10px] ">Preguntas:</p>
                    <p className="mt-2 max-sm:text-[10px]">{test.totalQuestions}</p>
                  </div>
                </div>
                <div className="flex flex-1">
                  <div className="flex flex-col gap-4 justify-center h-full py-4 px-4 relative max-sm:py-0">
                    <div className={`${!test?.customTest ? ' h-[70%]' : 'h-[50%]'} bg-gray-200 w-[2px] absolute left-[48%]`}></div>
                    <div className="bg-[#83c8df] w-8 h-8  max-sm:w-6  max-sm:h-6 text-white font-bold text-sm rounded-full flex items-center justify-center z-10">
                      {!test?.customTest && 1}
                    </div>
                    <div className="bg-[#83c8df] w-8 h-8 text-white max-sm:w-6  max-sm:h-6 font-bold text-sm rounded-full flex items-center justify-center z-10">
                      {!test?.customTest && 2}
                    </div>
                    {!test?.customTest && <div className="bg-[#83c8df] w-8 h-8 max-sm:w-6  max-sm:h-6 text-white font-bold text-sm rounded-full flex items-center justify-center z-10">
                      3
                    </div>}
                  </div>
                  <div className="flex flex-col justify-center gap-6">
                    <p className="font-medium max-sm:text-xs">{test.type}</p>
                    <p className="font-medium max-sm:text-xs">{test.standard}</p>
                    {!test?.customTest && <p className="font-medium max-sm:text-xs">{test.subject_block}</p>}
                  </div>
                </div>
                <div className="flex flex-col gap-1 w-[12%]">
                  <div
                    className="h-[50%]  bg-[#ededed] flex items-center justify-center cursor-pointer hover:bg-[#d9d9d9]"
                    onClick={() => {
                      if (test.type === 'Simulacro') {
                        recoverTestGVA([test.type, test.standard, test.subject_block].join(' '), test._id, test.timeLeft);
                      } else if (test.type === 'Normativa') {
                        recoverTesLaw(test.subject_block, test.standard, test._id, test.timeLeft);
                      } else if (test?.customTest) {
                        recoverCustomTest(test._id, test.timeLeft)
                      }
                    }}
                  >
                    <img src={IconEye} alt="eyeIcon" className="w-11 h-11 max-sm:h-[30px] max-sm:2-[30px] " />
                  </div>
                  <div
                    className="h-[50%]  bg-[#ededed] flex items-center justify-center cursor-pointer hover:bg-[#d9d9d9]"
                    onClick={() => setOpenModal({ visible: true, testId: test._id })}
                  >
                    <img src={IconTrash} alt="trashIcon" className="max-sm:h-[30px]" />
                  </div>
                </div>

              </div>
            );
          })}

          {isLoading &&
            <div className="loader bg-white p-5 rounded-full flex space-x-3">
              <div className="w-4 h-4 bg-primary-100 rounded-full animate-bounce"></div>
              <div className="w-4 h-4  bg-primary-100  rounded-full animate-bounce"></div>
              <div className="w-4 h-4  bg-primary-100  rounded-full animate-bounce"></div>
              <div className="w-4 h-4  bg-primary-100  rounded-full animate-bounce"></div>
            </div>
          }
        </div>
      </div>
      <Modal
        show={openModal.visible}
        popup={true}
        onClick={() => setOpenModal({ visible: false, testId: '' })}
      >
        <div className="text-center mt-8">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400" />
          <h3 className="mb-5 text-lg font-normal text-gray-700">
            ¿Estás seguro de que deseas eliminar este test guardado?
          </h3>
          <div className="flex justify-center gap-4 mb-5">
            <Button
              color="gray"
              onClick={() => setOpenModal({ visible: false, testId: '' })}
            >
              Cancelar
            </Button>
            <Button
              disabled={deletTestLoading}
              className="md:mr-4 bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]"
              color="primary-100"
              type="submit"
              onClick={() => deleteTestUnfinished(openModal.testId)}
            >
              {deletTestLoading ? 'Borrando le test...' : 'Aceptar'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
