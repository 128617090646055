import { ContactBanner } from "./sections/ContactBanner";
import QVEBanner from "./sections/QVEBanner";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";
import CTABanner from "./sections/CTABanner";
import FourthSection from "./sections/FourthSection";
import FAQS from "../../FAQS/FAQS";
import navbaricon3clicks from '../../../../assets/images/logo3clicks.png';


export default function NoLoggedHome() {
  return (
    <div className="flex flex-col gap-16">
      <div className="mx-auto">
        <img src={navbaricon3clicks} />
      </div>
      <FirstSection/>
      <SecondSection/>
      <ThirdSection/>
      <CTABanner />
      <FourthSection />
      <QVEBanner />
      <FAQS/>
      <ContactBanner/>
    </div>
  );
}
