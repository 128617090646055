import { useNavigate } from 'react-router-dom';
import loggedIcon from '../../../../assets/images/loggedicon.png';
import { ROUND_BUTTON } from '../../../config/constants';
import { useAuth } from '../../../contexts/authContext';
import MainButton from '../../buttons/MainButton';
import { useSubscription } from '../../../contexts/subscriptionContext';


const PrincipalFeature = () => {

  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row flex-wrap-reverse items-center justify-center p-12 xl:px-60">
      <div className="flex flex-col items-center w-full sm:w-1/2 p-6">
        <img src={loggedIcon} alt={'App Auténtica Test'} />
      </div>
      <div className="flex flex-col items-center text-center sm:items-start sm:text-left font-sans w-full sm:w-1/2 text-left sm:px-6">
        <h1 className="text-3xl md:text-5xl text-black font-semibold mb-5">¡Cada día más cerca de conseguir tu objetivo!</h1>
        <MainButton
          className="w-40"
          type={ROUND_BUTTON}
          onClick={() => {
            if (!subscription.isActive()) {
              navigate('/tests/simulacrum');
            } else {
              navigate('/tests');
            }
          }}>
          ¡Comienza ahora!
        </MainButton>
      </div>
    </div>
  );
};

export { PrincipalFeature };