import { useEffect, useState } from "react";
import dateService from "../../services/date.service";
import { useAuth } from "../../contexts/authContext";
import PaymentService from "../../services/httpServices/payment.service";
import UserService from "../../services/httpServices/user.service";
import { storageService } from "../../services/storageService/storageService";
import ToastService from "../../services/toastService";
import MainButton from "../buttons/MainButton";
import { SECONDARY_BUTTON, SECONDARY_DESIST_BUTTON } from "../../config/constants";
import Skeleton from 'react-loading-skeleton'
import ConfirmationModal from "../modals/ConfirmationModal";
import paymentServiceStripe from "../../services/paymentStripe.service";
import { useSubscription } from "../../contexts/subscriptionContext";
import { useNavigate } from "react-router-dom";


export default function SubscriptionComponent({ className = '' }: { className?: string }): JSX.Element {
  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const { billingDashboard } = PaymentService();
  const { getInfoUser, deleteAccount } = UserService();
  const { showErrorToast } = ToastService();

  const [isLoading, setIsLoading] = useState(true);
  const [isViewModal, setIsViewModal] = useState(false);
  const { createCheckoutSession } = PaymentService();

  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser({ ...user, hasDetails: true });
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (auth.user) {
      if (!auth.user.hasDetails) getUserData();
      else setIsLoading(false);
    }
  }, [auth.user]);

  const onBillingDashboard = async () => {
    try {
      const url = await billingDashboard();
      window.location.href = url;
    } catch (error) {
      // TODO: handle error
    }
  };

  const subscribe = async () => {
    const createSesion = await createCheckoutSession(auth.user.email);
    await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
  }

  const cancelSubscription = async () => {
    deleteAccount().then(() => {
      setIsViewModal(false)
      auth.updateUser({ stripe_subscription_status: 'canceled' })
    }).catch(() => {
      showErrorToast('No se ha podido cancelar la cuenta, inténtelo más tarde');
    });
  };

  return (
    <>
      <div className="flex flex-col items-center px-6">
        <ConfirmationModal
          text={'¿Seguro que deseas cancelar la suscripción? ¡Dejarás de tener acceso a las 16.000 preguntas de test y los simulacros de la plataforma!'}
          onClose={() => cancelSubscription()}
          onAccept={() => setIsViewModal(false)}
          visible={isViewModal}
          cancelText='Cancelar subscripcion'
          acceptText='Mantenerla'
        />
        <h2 className="text-center text-1xl text-text-100 font-semibold">
          Tu suscripción de Auténtica Test:
        </h2>
        <div className="flex flex-col text-center items-center w-full text-text-100 sm:max-w-[400px] border-[1px] border-gray-400 rounded-md px-5 py-8 my-7">
          {isLoading ?
            <div className="w-80 flex flex-col gap-4">
              <Skeleton count={2} />
              <Skeleton count={2} />
              <Skeleton count={2} />
            </div> :
            <>
              <div className={`mb-7 ${subscription.paymentHasFailed() && "text-red-600"}`} >
                <h1 className="font-bold">Suscripción</h1>
                <span>{subscription.getStatusRenderableString()}</span>
              </div>
              {subscription.hasSubscription() &&
                <>
                  <div>
                    {auth.user.subscription_created_at &&
                      <div>
                        <span className="font-bold">Fecha de suscripción:</span>
                        <span> {dateService.format(new Date(auth.user.subscription_created_at), 'DD-MM-YYYY')}</span>
                      </div>
                    }
                  </div>
                  <div>
                    {auth.user.stripe_next_payment_date &&
                      <div>
                        <span className="font-bold">Próxima cuota:</span>
                        <span> {dateService.format(new Date(auth.user.stripe_next_payment_date), 'DD-MM-YYYY')}</span>
                      </div>
                    }
                  </div>
                  <div>
                    <h1 className="font-bold">Precio</h1>
                    <span>9.90€ cada 31 días</span>
                  </div>
                </>
              }
            </>
          }
        </div>
        {subscription.hasSubscription()
          ?
          <div className="flex flex-col gap-2 w-full sm:max-w-[300px]">
            <MainButton type={SECONDARY_BUTTON} className="w-full" onClick={onBillingDashboard} disabled={isLoading}>
              {subscription.paymentHasFailed() ? "Pagar importe adeudado" : "Modificar datos de la suscripción"}
            </MainButton>
            <MainButton className="w-full" onClick={() => setIsViewModal(true)} disabled={isLoading} type={SECONDARY_DESIST_BUTTON}>
              Cancelar suscripción
            </MainButton>
          </div>
          :
          <div className="flex flex-col gap-2 w-full sm:max-w-[300px]">
            <MainButton className="w-full" onClick={subscribe} disabled={isLoading}>
              Suscribirse
            </MainButton>
          </div>
        }
      </div>
    </>
  );


};
