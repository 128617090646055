import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionService from "../../../services/httpServices/question.service";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from "react-icons/go";
import { useAuth } from "../../../contexts/authContext";
import Skeleton from "react-loading-skeleton";
import { useLocation } from 'react-router-dom';


export type AmountOptions = 10 | 20 | 40;

export default function SelectStandard(): JSX.Element {

  const auth = useAuth();
  const params = useParams();
  const { getStandards } = QuestionService();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [standards, setStandards] = useState<{ amounts: number[], name: string }[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const title = state.title ? (params.subjectblock + ' ▶ ' + state?.title.split(' ').slice(1).join(' ▶ ')) : 'NORMATIVA';

  const calcAmounts = (amount: any) => {
    return amount <= 100 ? [10, 20] : [10, 20, 40];
  };



  useEffect(() => {
    setIsLoading(true);
    async function onGetStandards() {
      if (params.subjectblock && !state.laws) {
        const fetchStandards: { count: number, _id: string }[] = await getStandards(params.subjectblock);
        setIsLoading(false);
        const amounts = fetchStandards.map((v) => ({ name: v._id, amounts: calcAmounts(v.count) }));
        const orderStandards = amounts.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1);
        setStandards(orderStandards);
      } else {
        setIsLoading(false);
        const amounts = state.laws.map((v: any) => ({ name: v._id, amounts: calcAmounts(v.count), range_article: v.range_article, title: v.title, level_range: v.level_range }));
        const orderStandards = amounts.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1);
        setStandards(orderStandards);
      }
    }
    onGetStandards();
  }, []);

  return (
    <div className="relative sm:pt-20 sm:pb-10 flex-1 flex flex-col items-center justify-center bg-background-200">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => navigate(-1)}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>

      <div className="absolute top-6 right-6">
        <label htmlFor="study-mode" className="flex items-center cursor-pointer relative">
          <input
            type="checkbox"
            id="study-mode"
            className="sr-only peer"
            checked={auth.user?.studyMode}
            onChange={(e) => {
              auth.updateUser({ studyMode: e.target.checked });
            }}
          />
          <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
          <span className="text-text-300 text-sm font-medium text-center ml-2">Modo estudio</span>
        </label>
      </div>

      <h1 className="mt-28 mb-16 sm:mt-0 text-[25px] max-sm:text-[30px] text-center tracking-[10px] text-text-300 font-light max-sm:px-4">
        {title.toUpperCase()}
      </h1>
      <div className="w-full flex items-center justify-center px-8">
        {isLoading ?
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
          </div> :
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7 ">
            {standards?.map((standard: any, index: number) => (
              <div
                className='flex-1 w-full sm:flex-initial sm:w-[260px] sm:min-h-[180px] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white p-10 rounded-3xl shadow-xl hover:scale-110'
                key={`regulations-card-${index}`}>
                <span className="select-none font-bold text-xl">{standard.name}</span>
                <div className="flex gap-4 mt-5">
                  {standard.amounts.map((amount: any, i: number) => (
                    <div
                      key={`amount-dot-${index}`}
                      className="transition bg-white select-none cursor-pointer flex items-center justify-center text-primary-200 rounded-full w-[45px] h-[45px] text-xl font-medium hover:bg-primary-200 hover:ring-1 hover:ring-white hover:text-white"
                      onClick={() =>
                        navigate(`/tests/regulations/${params.subjectblock}/${encodeURIComponent(standard.name)}/${amount}`, {
                          state: {
                            title: standard?.title,
                            range_article: standard.range_article,
                            level_range: standard.level_range,
                            type: state.title ? 'customLaw' : 'law'
                          }
                        })
                      }>
                      {amount}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
};
