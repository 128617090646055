import { useAuth } from '../../../contexts/authContext';
import { useSubscription } from '../../../contexts/subscriptionContext';
import { NoPaidModal } from '../SubscriptionFailureModals';
import { PrincipalFeature } from './PrincipalFeature';


export default function LoggedHomeComponent() {

  const subscription = useSubscription();

  return (
    <>
      { subscription.paymentHasFailed() && 
        <NoPaidModal/>
      }
      <PrincipalFeature/>
    </>
  );
}
