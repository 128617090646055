import { useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/buttons/MainButton";
import { SECONDARY_BUTTON, SECONDARY_GRAY_BUTTON } from "../../config/constants";
import { useEffect, useRef, useState } from "react";
import { IQuestion, ISection, ITest, ITestTosave, IQuestionAnswer } from "../../interfaces";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAuth } from "../../contexts/authContext";
import TestService from "../../services/httpServices/testService";
import { useLocation } from "react-router-dom";
import Timer from "../../components/test/Timer";
import logoAutentica from "../../../assets/icons/logo.png";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import TestSection from "../../components/test/TestSection";
import Skeleton from "react-loading-skeleton";
import { Doughnut } from "react-chartjs-2";
import ToastService from "../../services/toastService";
import { useSubscription } from "../../contexts/subscriptionContext";
import TrailingBanner from "./trailingBanner";
import { capitalizeFirstLetter } from "../../helpers/stringFormater";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
export interface ITestComponentProps {
  params: any
}

interface TypeOfTestState {
  subjectBlock?: string;
  standart?: string;
  size?: number;
  type?: string;
  isLocal?: boolean;
}
export default function TestView(): JSX.Element {

  const params = useParams();


  const subscription = useSubscription();
  const { showErrorToast, showSuccessToast } = ToastService();
  const {
    getLaw,
    getSimulation,
    getTestQuestionsFailed,
    getTestQuestionsNotAnswered,
    getTrialingTest,
    correctTest,
    saveTestNoFinalized,
    getLawCustom
  } = TestService();
  const navigate = useNavigate();
  const testScrollRef = useRef<any>(null);
  const location = useLocation();
  const path = location.pathname.slice(1);
  const auth = useAuth();


  const initialModalData = {
    visible: false,
    text: '',
    action: null,
    body: null,
    acceptText: null,
    cancelText: null,
    onFinishLater: null,
  }
  const [onFinishLaterPressed, setOnFinishLaterPressed] = useState(false);
  const [modalData, setModalData] = useState<any>(initialModalData);
  const [test, setTest] = useState<ITest>({
    _id: '',
    name: '',
    sections: [],
    _questions: [],
    created_at: new Date(),
    updated_at: new Date(),
    idTestRecovered: '',
    timeLeft: 0
  });
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [isUnfinishedTest, setIsUnfinishedTest] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [correctMode, setCorrectMode] = useState(false);
  const [questionsCount, setQuestionsCount] = useState({ correct: 0, failed: 0, not_answered: 0, doubtful: 0, total: 0 });
  const [typeOfTest, setTypeOfTest] = useState<TypeOfTestState>({ subjectBlock: '', standart: '', size: 0, type: '', isLocal: false });
  const [testGrade, setTestGrade] = useState<string>('0.00');
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [lodingSaveTest, setLodingSaveTest] = useState<boolean>(false)
  const [studyModeChanged, setStudyModeChanged] = useState<boolean>(false)


  function disableStudyMode() {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');

    for (const segment of pathSegments) {
      if (segment === "testUnfinished" || segment === "trialing" || segment.startsWith("Simulacro") && auth.user.studyMode) {
        auth.updateUser({ studyMode: false });
        setStudyModeChanged(true)
      }
    }

  }

  useEffect(() => {
    disableStudyMode()
  }, []);


  const dataDoughnut = {
    labels: ['Preguntas acertadas', 'Falladas', 'No contestadas'],
    datasets: [
      {
        data: [questionsCount.correct, questionsCount.failed, questionsCount.not_answered],
        backgroundColor: [
          '#5dbb63',
          'rgb(215, 0, 64, 1)',
          'rgb(122, 207, 229, 0.9)',
        ],
        borderWidth: 0,
      },
    ],
  };

  useEffect(() => {
    if (location.state && location.state.test && location.state.type == 'unfinishedTest') {
      setIsUnfinishedTest(true)
      setTest(location.state?.test);
      setTotalQuestions(location.state?.test._questions.length);
      setIsLoading(false)
    } else if (location.state && location.state.test && location.state.type == 'customTest') {

      setTest(location.state?.test);
      setTotalQuestions(location.state?.test._questions.length);
      setTypeOfTest({
        ...typeOfTest, subjectBlock: 'Test personalizado', standart: capitalizeFirstLetter(location.state?.test.name), type: 'Test personalizado', size: location.state?.test._questions.length
      })
      setIsLoading(false)
    } else if (location.state && location.state.test && location.state.type == 'testWithDoubtful') {
      setTest(location.state?.test);
      setTotalQuestions(location.state?.test._questions.length);
      setIsLoading(false)

    }

  }, [location])




  useEffect(() => {
    const promises = [];
    if (params.subjectblock && params.size && location.state.type == 'law') {
      setTypeOfTest((prev: TypeOfTestState) =>
      ({
        ...prev,
        type: 'Normativa',
        size: Number(params.size),
        subjectBlock: params?.subjectblock,
        standart: params?.standard
      }));
      const p = getLaw(parseInt(params.size), params.standard, params.subjectblock).then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
        } else throw new Error();
      })
      promises.push(p);
    }

    if (params.subjectblock && params.size && location.state.type == 'customLaw') {

      setTypeOfTest((prev: TypeOfTestState) =>
      ({
        ...prev,
        type: 'Normativa',
        size: Number(params.size),
        subjectBlock: params?.subjectblock,
        standart: params?.standard
      }));

      const p = getLawCustom(parseInt(params.size), params.standard, params.subjectblock, location.state.title, location.state.range_article, location.state.level_range).then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
        } else throw new Error();
      })
      promises.push(p);
    }

    if (params.name) {
      const parametro = params?.name.split(' ')
      const opoSubjectBlock = [parametro[2], parametro[3], parametro[4]].filter(Boolean).join(' ')
      const p = getSimulation(params.name).then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
          setTypeOfTest((prev: TypeOfTestState) => ({ ...prev, type: parametro[0], size: response._questions.length, subjectBlock: opoSubjectBlock, standart: parametro[1] }));
        } else throw new Error();
      })
      promises.push(p);
    }

    if (params.failed) {
      const p = getTestQuestionsFailed().then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
        } else throw new Error();
      })
      promises.push(p);
    }

    if (params.notAnswered) {
      const p = getTestQuestionsNotAnswered().then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
        } else throw new Error();
      })
      promises.push(p);
    }

    if (path.includes('trialing') && params.id) {
      const p = getTrialingTest(params.id).then((response) => {
        if (response.sections) {
          setTest(response);
          setTotalQuestions(response._questions.length);
          const parametro = response.name.split(' ')
          setTypeOfTest({ type: parametro[0], size: response._questions.length, subjectBlock: parametro[2], standart: parametro[1], isLocal: true });
        } else throw new Error();
      })
      promises.push(p);
    }

    Promise.all(promises)
      .catch(err => {
        showErrorToast('Se ha producido un error al cargar el test, por favor, vuelve a intentarlo más tarde');
        navigate(-1);
      })
      .finally(() => setIsLoading(false));

  }, []
  );


  const onResetTest = () => {
    setModalData({
      visible: true,
      text: '¿Estás seguro de que quieres reiniciar el test?',
      acceptText: 'Cancelar',
      cancelText: 'Reiniciar',
      onClose: resetTestHandler,
      action: (() => setModalData(initialModalData))
    })
  };

  const resetTestHandler = () => {
    test?.sections.forEach((section: ISection) => {
      section.questions.forEach((question: IQuestion) => {
        question.isCorrected = undefined;
        question.user_answer = undefined;
      })
    })
    setTest({ ...test! })
    setModalData(initialModalData);
  }

  const onExitTest = () => {
    setModalData({
      acceptText: "Quedarme",
      cancelText: "Salir igualmente",
      finishedLater: !path.includes('trialing') && !correctMode && location.state.type !== 'testWithDoubtful' && "Guardar el progresso y salir",
      visible: true,
      text: `${auth.token ?
        (correctMode ?
          '¿Deseas salir del test?' :
          '¿Deseas salir del test sin corregirlo?'
        ) :
        (correctMode ?
          '¿Deseas salir del test?' :
          '¿Deseas salir del test sin corregirlo?'
        )
        }`,
      action: (() => setModalData(initialModalData)),
      onClose: exitTestHandler,
      onFinishLater: !path.includes('trialing') && onFinishLater
    });
  };

  const exitTestHandler = async () => {
    if (studyModeChanged) {
      auth.updateUser({ studyMode: true });
    }
    navigate('/');
  };

  const qualifyQuestions = async () => {
    let count = { correct: 0, failed: 0, not_answered: 0, doubtful: 0, total: totalQuestions };
    test?.sections.forEach(section => {
      section.questions.map((question) => {
        if (!question.user_answer) count = { ...count, not_answered: count.not_answered + 1 };
        else if (question.user_answer === question.answer) count = { ...count, correct: count.correct + 1 };
        else count = { ...count, failed: count.failed + 1 };
        if (question.doubtful) count = { ...count, doubtful: count.doubtful + 1 };
      });
    })
    setQuestionsCount(count);
    return count
  };

  const setGrade = async () => {
    const count = await qualifyQuestions();
    const grade = (((count.correct - (count.failed / 3)) / totalQuestions) * 10).toFixed(2);
    setTestGrade(grade);
  };

  const onCorrectTest = async () => {
    setGrade();
    setCorrectMode(true);

    const questionsToCorrect: any[] = [];
    test?.sections.forEach(section => {
      section.questions.forEach(question => {
        question.isCorrected = true;
        questionsToCorrect.push({
          _id: question._id,
          answer: question.user_answer
        })
      });
    });
    setTest({ ...test! });
    testScrollRef.current.scroll({ top: 0, behavior: 'smooth' })
    if (auth.token) {
      correctTest(questionsToCorrect, test?._id)
        .catch(() => {
          // TODO: handle error
        })
    }
  };

  const acceptFinishTest = async (data: any) => {
    setModalData(initialModalData);
    if (path.includes('trialing') && !auth.token) {
      setModalData({
        visible: true,
        text: 'Aún no estás suscrito, ¿deseas suscribirte?',
        action: () => {
          navigate('/register')
        },
        onClose: () => {
          setModalData(initialModalData);
          onCorrectTest();
        }
      });
    } else onCorrectTest();
  };

  const renderSections = () => {
    const items: any = [];
    let numQuests = 0;
    test?.sections.forEach((section: ISection, i: number) => {
      items.push(
        <TestSection
          type={location?.state?.type}
          key={`test-section-${i}`}
          section={section}
          onAnswerQuestion={(index: number, ans: any) => {
            section.questions[index].user_answer = ans;
            setTest({ ...test });
          }}
          onCorrectQuestion={(index: number) => {
            section.questions[index].isCorrected = true;
            setTest({ ...test });
          }}
          onMarkedAsDoubtful={(index: number, marked: boolean) => {
            section.questions[index].doubtful = marked;
            setTest({ ...test });
          }}
          indexStartAt={numQuests}
          totalQuestions={totalQuestions}
          correctMode={correctMode}
        />
      );
      numQuests += section.questions.length;
    })
    return items;
  }


  const shouldDisplaySubscriptionBanner = (): boolean => {
    return path.includes('trialing');
  }


  useEffect(() => {
    setTimeLeft(timeLeft)
  }, [timeLeft])




  const onFinishLater = () => {
    setLodingSaveTest(true)
    const questionToSave = test?.sections.map((section) => {
      return section.questions.map((question) => {
        const questionToSave: IQuestionAnswer = {
          _id: question?._id,
          answer: question?.user_answer,
          doubtful: question?.doubtful,
        };
        return questionToSave
      });
    })
    const testToSave: ITestTosave = {
      idTestRecovered: test.idTestRecovered,
      questions: questionToSave,
      type: typeOfTest.type,
      subject_block: typeOfTest.subjectBlock,
      standard: typeOfTest.standart,
      timeLeft: timeLeft,
      customTest: location.state?.sectionsStructure,
    };
    saveTestNoFinalized(testToSave).then((response) => {
      showSuccessToast(response.message);
      setTest({ ...test, idTestRecovered: response.data._id });
      exitTestHandler()
    }).catch((error) => {
      showErrorToast(error);
      setLodingSaveTest(false)
    })
      .finally(() => setLodingSaveTest(false))
  }


  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between items-center px-4 py-2">
        <div className="w-full flex items-center justify-between my-2 sm:my-0 ">
          <div className="max-w-[100px]">
            <img
              src={logoAutentica}
              alt="Auténtica test logo"
            />
          </div>
          {!correctMode && !auth?.user?.studyMode && !isLoading &&
            <Timer
              setTimeLeft={setTimeLeft}
              className="flex sm:hidden min-h-[45px]"
              time={isUnfinishedTest ? test.timeLeft : totalQuestions * 60000}
              correctMode={correctMode}
              onTimeExpired={() => {
                setModalData({
                  visible: true,
                  text: 'El tiempo se te ha acabado se procederá a corregirse el examen',
                  action: acceptFinishTest,
                });
              }}
              onStopTime={() => {
                setModalData({
                  visible: true,
                  text: 'El tiempo se te ha acabado, ¿quieres corregir el test?',
                  action: acceptFinishTest,
                });
              }}
            />
          }
        </div>
        <div className="w-full sm:w-auto flex flex-col sm:flex-row items-center gap-3">
          {!correctMode &&
            <>
              {!path.includes('trialing') && location?.state?.type !== 'testWithDoubtful' && <MainButton
                className="w-full sm:w-auto"
                type={SECONDARY_BUTTON}
                disabled={isLoading}
                onClick={async () => {
                  setModalData({
                    visible: true,
                    text: `Al guardar el progreso saldrás del test para poder continuarlo más tarde.
                             Para continuar un test guardado puedes hacerlo desde la opción “Mis test guardados“ del menú de usuario.`,
                    acceptText: "Guardar",
                    cancelText: "Continuar sin guardar",
                    action: onFinishLater,
                  });
                }}>
                Guardar
              </MainButton>}
              <MainButton
                className="w-full sm:w-auto"
                type={SECONDARY_GRAY_BUTTON}
                disabled={isLoading}
                onClick={() => onResetTest()}>
                Restablecer
              </MainButton>
            </>
          }
          <MainButton
            className="w-full sm:w-auto"
            type={SECONDARY_BUTTON}
            disabled={isLoading}
            onClick={() => onExitTest()}>
            Salir
          </MainButton>
          {!correctMode &&
            <MainButton
              className="w-full sm:w-auto"
              disabled={isLoading}
              onClick={async () => {
                const count = await qualifyQuestions();
                setModalData({
                  visible: true,
                  text: `¿Deseas corregir el test?`,
                  acceptText: "Corregir",
                  body: `${count.total - count.not_answered} de ${count.total} contestadas \n ${count.not_answered} de ${count.total} sin contestar \n ${count.doubtful} de ${count.total} marcadas como dudosas `,
                  action: acceptFinishTest,
                });
              }}>
              Corregir
            </MainButton>
          }
        </div>
      </div>

      {shouldDisplaySubscriptionBanner() && <TrailingBanner />}

      <div
        ref={testScrollRef}
        className={`flex-1 overflow-y-auto flex flex-col items-center border-gray-200 bg-background-100 px-6 md:px-[10%] pb-16 ${!path.includes('trialing') && 'border-t-[1px]'}`}>
        {isLoading ?
          <div className="flex flex-col items-center w-full mt-16">
            <Skeleton className="min-w-[60vw] mb-2" height={40} />
            <Skeleton className="min-w-[40vw] mb-16" />
            <Skeleton className="min-w-[90vw] mb-7" height={'18vh'} />
            <Skeleton className="min-w-[90vw] mb-7" height={'18vh'} />
            <Skeleton className="min-w-[90vw] mb-7" height={'18vh'} />
          </div> :
          <div className="w-full">
            <div className={`flex gap-5 
              ${auth?.user?.studyMode ? 'mt-8' : 'mt-12'} 
              ${correctMode ?
                'justify-between mb-16' :
                `justify-center ${auth?.user?.studyMode ? 'mb-5' : 'mb-16'}`
              }`}>
              <div className="flex flex-col justify-between gap-2">
                <div className={correctMode ? 'text-left' : 'text-center'}>
                  <span className="font-bold text-3xl text-text-200">
                    {test?.name}
                  </span>
                  <p className="text-text-200 text-light text-xl">
                    {test?._questions.length.toString()} preguntas
                  </p>
                  {correctMode &&
                    <div className="font-light text-text-200 mt-5">CALIFICACIÓN:</div>
                  }
                </div>
                {correctMode &&
                  <div className="font-bold text-[85px] text-text-200 my-[-35px] ml-[-5px]">
                    {testGrade}
                  </div>
                }
              </div>
              {correctMode &&
                <div className="max-w-[200px]">
                  <Doughnut
                    data={dataDoughnut}
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        },
                      },
                    }}
                  />
                </div>
              }
            </div>

            <div className="flex flex-col gap-24">
              {renderSections()}
            </div>
          </div>
        }
      </div>
      <ConfirmationModal
        disabeldBottons={lodingSaveTest}
        onFinishLater={modalData.onFinishLater}
        text={modalData.text}
        body={modalData.body}
        acceptText={modalData.acceptText}
        cancelText={modalData.cancelText}
        finishLater={modalData.finishedLater}
        onAccept={() => modalData.action()}
        onClose={modalData.onClose ?? (() => setModalData(initialModalData))}
        visible={modalData.visible} />
    </>
  );
}