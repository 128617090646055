import React from 'react';
import { useAuth } from '../contexts/authContext';


const SubscriptionContext = React.createContext();

export const SubscriptionProvider = ({ children }) => {

  const auth = useAuth();

  const isActive = () => {
    return auth.user && auth.user.stripe_subscription_status && auth.user.stripe_subscription_status == "active"
  }
    
  const paymentHasFailed = () => {
    return auth.user && auth.user.stripe_subscription_status && auth.user.stripe_subscription_status == "unpaid"
  }
    
  const hasSubscription = () => {
    return auth.user && auth.user.stripe_subscription_status && auth.user.stripe_subscription_status != "canceled"
  }
    
  const getStatusRenderableString = () => {
    if(hasSubscription()){
      switch(auth.user.stripe_subscription_status){
        case 'active':
          return "Suscrito"
        case 'unpaid':
          return "Problemas de pago"
        case 'trialing':
          return "Prueba"
        default:
          return 'No suscrito'
      }
    }else{
      return 'No suscrito'
    }
  }


  return(
    <SubscriptionContext.Provider value={{ isActive, paymentHasFailed, hasSubscription,getStatusRenderableString }}>
      { children }
    </SubscriptionContext.Provider>
  )
};

export const useSubscription = () => {
  return React.useContext(SubscriptionContext);
}