import { useAxios } from "../../contexts/axiosContext";


export default function QuestionService() {

  const { publicAxios } = useAxios();

  function getQuestionsForCreateSimulations() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question`, {
        params: {
          "aggregate": '[{"$group":{"_id":{"subject_block":"$subject_block","standard":"$standard","title":"$title"},"total":{"$sum":1}}},{"$group":{"_id":{"subject_block":"$_id.subject_block","standard":"$_id.standard"},"total":{"$sum":"$total"},"titles":{"$push":{"_id":"$_id.title","total":"$total"}}}},{"$group":{"_id":"$_id.subject_block","total":{"$sum":"$total"},"standards":{"$push":{"_id":"$_id.standard","total":"$total","titles":"$titles"}}}}]'
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getAllQuestions() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function countAllQuestions() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question/count`, {
        params: {
          "resource": '{}'
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function uploadQuestions(file, override) {
    const formData = new FormData();
    formData.append('questions', file);
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/upload/questions`, formData, {
        params: {
          'override': override
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getSubjectBlocks() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question`, {
        params: {
          "disctinct": 'subject_block'
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getStandards(subjectblock) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question`, {
        params: {
          "aggregate": `[{"$match":{"subject_block":"${subjectblock}"}},{"$group":{"_id":"$standard","count":{"$sum":1}}}]`
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function deleteQuestion(ids) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/question/delete/admin`, { ids })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updateQuestion(id, question) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/question/${id}`, question)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function downloadQuestionsExcel(ids) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/question/download`, { ids }, {
        responseType: 'blob'
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function downloadQuestionsPDF(ids) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/question/downloadpdf`, { ids }, {
        responseType: 'blob'
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getBlocksOfSimmulation(simulacrum) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/question/blocks-simulation?testName=${simulacrum}`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    getBlocksOfSimmulation,
    getQuestionsForCreateSimulations,
    getAllQuestions,
    countAllQuestions,
    uploadQuestions,
    getSubjectBlocks,
    getStandards,
    deleteQuestion,
    updateQuestion,
    downloadQuestionsExcel,
    downloadQuestionsPDF
  };
}