import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/authContext";
import UserService from "../../../services/httpServices/user.service";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from 'react-icons/go';
import { NoPaidModal, NoSubscriptionModal } from "../../../components/home/SubscriptionFailureModals";
import { useSubscription } from "../../../contexts/subscriptionContext";



export default function SelectSimulacrum(): JSX.Element {

  const auth = useAuth();
  const navigate = useNavigate();
  const { getInfoUser } = UserService();
  const subscription = useSubscription();

  const [isLoading, setIsLoading] = useState(true);

  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser({ ...user, hasDetails: true });
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (!auth.token) setIsLoading(false);
  }, []);

  useEffect(() => {
    if (auth.user) {
      if (!auth.user.hasDetails) getUserData();
      else setIsLoading(false);
    }
  }, [auth.user]);

  return (
    <div className="relative flex flex-col flex-1">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => {
          if (subscription.isActive()) navigate(-1)
          else navigate('/')
        }}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      {!isLoading &&
        <>
          {subscription.paymentHasFailed() &&
            <NoPaidModal />
          }
          {!subscription.hasSubscription() &&
            <NoSubscriptionModal />
          }
          <div className="flex-1 flex flex-col lg:flex-row">

            <div
              className="p-10 transition-all ease-in-out text-base hover:text-2xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-white cursor-pointer text-primary-100"
              onClick={() => navigate('/tests/simulacrum/GVA')}>
              <div className="text-center font-semibold text-4xl">
                GVA
              </div>
              <div className='w-[2rem] h-[2rem] bg-primary-100 rounded-full my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]'></div>
              <div className="text-center font-semibold">
                Oposición Administración General <br></br> de la Generalitat Valenciana
              </div>
            </div>

            <div
              className="p-10 transition-all text-base ease-in-out hover:text-2xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-primary-100 cursor-pointer text-white"
              onClick={() => navigate('/tests/simulacrum/LOCAL')}>
              <div className="text-center font-semibold text-4xl">
                LOCAL
              </div>
              <div className='w-[2rem] h-[2rem] bg-white rounded-full my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]'></div>
              <div className="text-center font-semibold">
                Oposición Administración Local, <br></br> Ayuntamientos o Diputaciones de la <br></br> Comunidad Valenciana
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};
