import { useEffect, useState, forwardRef, useImperativeHandle, } from "react";
import { BiTimeFive } from 'react-icons/bi';
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import { useTimer } from 'react-timer-hook';
import MainButton from "../buttons/MainButton";
import PauseModal from "./pauseModal";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";



interface TimerProps {
  time: number,
  correctMode: boolean,
  onTimeExpired: any,
  onStopTime: any,
  className: string,
  setTimeLeft: any

}

export default function Timer(props: TimerProps) {
  const { time, correctMode, onTimeExpired, onStopTime, setTimeLeft } = props;
  const expiryTimestamp = new Date(Date.now() + time);
  const { seconds, minutes, hours, pause, resume, isRunning } = useTimer({
    expiryTimestamp, onExpire: () => {
      onTimeExpired();
      onStopTime();
    },


  });
  const [expanded, setExpanded] = useState<boolean>(true);

  const togglePause = () => {
    isRunning ? pause() : resume()
  }

  useEffect(() => {
    setTimeLeft((3600000 * hours) + (60000 * minutes) + (1000 * seconds))
  }, [seconds, minutes, hours]);

  return (
    <div className="flex justify-items-end">
      <button onClick={() => {
        togglePause()
      }}>
        {
          isRunning ?
            <BsPauseCircleFill className={`transition-all text-3xl text-primary-100 md:hidden ${props.className}`}></BsPauseCircleFill>
            : <BsPlayCircleFill className={`transition-all text-3xl text-primary-100 md:hidden ${props.className}`}></BsPlayCircleFill>
        }
      </button>
      <div
        className={`transition-all select-none hover:bg-gray-100 rounded-md cursor-pointer flex items-center gap-1 text-text-300 `}
        onClick={() => setExpanded(!expanded)}>
        <div className={expanded ? '' : 'mr-[-5px]'}>
          {expanded ?
            <VscChevronRight size={18} /> :
            <VscChevronLeft size={18} />
          }
        </div>
        <div className={`transition-all text-center ${expanded ? 'text-base min-w-[72px]' : 'text-[0px] min-w-0'}`}>
          {correctMode ?
            <div>
              <span>00</span>:<span>00</span>:<span>00</span>
            </div>
            :
            <div>
              {
                hours < 10 ?
                  <div>
                    {
                      minutes < 10 ?
                        <div>
                          {
                            seconds < 10 ?
                              <div>
                                <span>0{hours}</span>:<span>0{minutes}</span>:<span>0{seconds}</span>
                              </div>
                              :
                              <div>
                                <span>0{hours}</span>:<span>0{minutes}</span>:<span>{seconds}</span>
                              </div>
                          }
                        </div>
                        :
                        <div>
                          {
                            seconds < 10 ?
                              <div>
                                <span>0{hours}</span>:<span>{minutes}</span>:<span>0{seconds}</span>
                              </div>
                              :
                              <div>
                                <span>0{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                              </div>
                          }
                        </div>
                    }

                  </div>
                  :
                  <div>
                    {
                      minutes < 10 ?
                        <div>
                          {
                            seconds < 10 ?
                              <div>
                                <span>0{hours}</span>:<span>0{minutes}</span>:<span>0{seconds}</span>
                              </div>
                              :
                              <div>
                                <span>0{hours}</span>:<span>0{minutes}</span>:<span>{seconds}</span>
                              </div>
                          }
                        </div>
                        :
                        <div>
                          {
                            seconds < 10 ?
                              <div>
                                <span>0{hours}</span>:<span>{minutes}</span>:<span>0{seconds}</span>
                              </div>
                              :
                              <div>
                                <span>0{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                              </div>
                          }
                        </div>
                    }
                  </div>
              }
            </div>
          }
        </div>
        <div className={`transition-all text-gray-500 ${expanded ? 'text-md' : 'text-2xl'}`}>
          <BiTimeFive onClick={() => setExpanded(!expanded)} />
        </div>
      </div>
      <MainButton
        onClick={togglePause}
        className={`hidden sm:flex sm:mx-3`}>
        {isRunning ? "Pausar" : "Reanudar"}
      </MainButton>
      <PauseModal
        show={isRunning}
        onClick={resume}
      ></PauseModal>
    </div>
  );
}

