import { useAuth } from "../../contexts/authContext";
import { useAxios } from "../../contexts/axiosContext";


export default function UserService() {

  const { publicAxios } = useAxios();
  const auth = useAuth();

  function getInfoUser() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/user/${auth.user.sub}`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function registerUser(user) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/register`, {user})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function sendEmailRecoverPassword(email) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/send/recover/password`, {email})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function recoverPassword(email, password) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/recover/password`, {email, password})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updateAvatar(avatar) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user`, {avatar})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getAllUsers() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/user`, {
        params: {
          "resource": `{"status_registered": "successfully registered"}`
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function deleteSubscription() {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/delete/subscription`, {})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  function deleteUser() {
    return new Promise((resolve, reject) => {
      publicAxios.delete(`/api/v1/user/` + auth.user._id, {})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function deleteAccountForAdmin(subscription_id) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/delete/admin/subscription`,
      {
        subscription_id
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updateUserForAdmin(user, isNewPassword) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/update/user/admin/${isNewPassword}`,
      {
        user
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updateUser(user) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/update`, {
        user
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updatePassword(password, newPassword) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/update/password`, {password, newPassword})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function addUserForAdmin(user) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/user/add/user/admin`, user)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    getInfoUser,
    registerUser,
    sendEmailRecoverPassword,
    recoverPassword,
    updateAvatar,
    getAllUsers,
    deleteAccount: deleteSubscription,
    deleteAccountForAdmin,
    deleteUser,
    updateUserForAdmin,
    updateUser,
    updatePassword,
    addUserForAdmin
  };
}