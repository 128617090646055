import computerHome from '../../../../../assets/images/principal.jpg';
import pruebaIcon from '../../../../../assets/icons/iconFreeTest.png';
import MainButton from '../../../buttons/MainButton';
import { FREE_TEST_TRIAL_URL, TRANSPARENT_BUTTON } from '../../../../config/constants';
import { useNavigate } from 'react-router-dom';


export default function FirstSection() {
  
  const navigate = useNavigate();

  return (
    <div className="flex flex-col sm:flex-row items-center justify-evenly px-8 xl:px-60">
      <div className="sm:px-6">
        <h1 className="text-4xl text-text-100 font-semibold" style={{fontFamily: 'serif'}}>Test de Oposiciones</h1>
        <h1 className="text-4xl text-primary-200 font-semibold" style={{fontFamily: 'serif'}}>Simulacros de examen</h1>
        <h3 className="text-2xl text-text-300 font-light">Generación Aleatoria</h3>
        <h3 className="mt-4 text-text-500 font-light">Un simulacro distinto cada vez. Más de 16.000 preguntas.</h3>
        <div className="mt-2 text-text-500 font-semibold">
          Adaptada a convocatorias de la Comunidad Valenciana: 
          <br></br>Generalitat y Entidades Locales.
        </div>
        <MainButton 
        type={TRANSPARENT_BUTTON} 
        className='px-0 mt-5 mb-5'
        onClick={() => { window.open(FREE_TEST_TRIAL_URL, '_blank', 'noreferrer') }}>
          <h1 className="text-lg text-primary-100 font-semibold">Prueba gratis aquí</h1>
          <img
            className="w-6 h-6"
            src={pruebaIcon}
            alt="image of de Auténtica test app"
          />                         
        </MainButton> 
      </div>
      <img src={computerHome} alt={'App Auténtica Test'} className='max-w-full sm:max-w-1/2 mx-auto sm:mx-0'/>
    </div>
  );
};